// @ts-strict-ignore
import _ from 'lodash';
import i18next from 'i18next';
import { parseQueryString, validateGuid } from '@/utilities/utilities';
import { LINK_TYPE } from '@/annotation/annotation.constants';

/**
 * @file Service that facilitates creating a calculation and then using that calculation to create results, such as
 * generated capsules or markers.
 */

const MAX_NAME_LENGTH = 20;
const MAX_DESCRIPTION_LENGTH = 50;

/**
 * Helper function than strips html characters, tokenizes the words in text, and returns the words at indexes
 * between start and end.
 */
function getWords(text: string, start: number, end: number) {
  if (!text) {
    return '';
  }
  // Remove tags, collapse spaces, split on space, slice desired words, join and return
  return _.compact(
    text
      .replace(/<[^>]*>/g, ' ')
      .replace(/\s+/g, ' ')
      .split(/\s+/gi),
  )
    .slice(start, end)
    .join(' ');
}

/**
 * Helper that gets the document name  and description.
 * The name is created by taking the first non-empty block of text (up to MAX_NAME_LENGTH) as the name.
 * The description consists of up to the next MAX_DESCRIPTION_LENGTH words in the document.
 *
 * @param htmlDocument - the html document from which the name and description are pulled.
 */
export function nameAndDescriptionFromDocument(htmlDocument: string): { name: string; description: string } {
  // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
  const ownerDocument = document.implementation.createHTMLDocument('virtual');
  ownerDocument.body.innerHTML = htmlDocument;

  const htmlElements = ownerDocument.body.childNodes;
  let name = '';
  _.forEach(htmlElements, function (child) {
    name = getWords(_.get(child, 'textContent'), 0, MAX_NAME_LENGTH);
    return _.isEmpty(name); // return false when name is determined to exit loop
  });

  name = name || i18next.t('UNNAMED');

  const start = _.split(name, ' ').length;
  const description = getWords(htmlDocument, start, start + MAX_DESCRIPTION_LENGTH);

  return { name, description };
}

/**
 * Extracts all the interests and date ranges that come from the journal-specific links.
 * @param htmlDocument - The journal document
 * @param isDiscoverable - If the journal is discoverable
 * @param extractAnchors - For use by tests
 */
export function interestsAndRangesFromDocument(
  htmlDocument: string,
  isDiscoverable: boolean,
  extractAnchors: (input: string) => HTMLAnchorElement[] = extractAnchorsFromHtmlString,
): {
  inputInterests: { interestId: string; detailId: string }[];
  ranges: { start: number; end: number }[];
} {
  const newInterests = [];
  const newRanges = [];

  if (isDiscoverable) {
    _.forEach(extractAnchors(htmlDocument), (a: any) => {
      let params;

      if (_.endsWith(a.pathname, '/links')) {
        params = parseQueryString(a.search);

        if (
          _.includes(
            [LINK_TYPE.SIGNAL, LINK_TYPE.CONDITION, LINK_TYPE.CAPSULE, LINK_TYPE.TABLE, LINK_TYPE.METRIC],
            params.type,
          )
        ) {
          if (validateGuid(params.item)) {
            newInterests.push({ interestId: params.item });
          }
        }

        if (params.type === LINK_TYPE.CAPSULE) {
          if (_.isFinite(+params.start) && _.isFinite(+params.end)) {
            newRanges.push({ start: +params.start, end: +params.end });
          }
        }
      }
    });
  }

  return {
    inputInterests: _.uniqWith(newInterests, _.isEqual),
    ranges: _.uniqWith(newRanges, _.isEqual),
  };
}

function extractAnchorsFromHtmlString(htmlDocument: string): HTMLAnchorElement[] {
  // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
  const ownerDocument = window.document.implementation.createHTMLDocument('virtual');
  ownerDocument.body.innerHTML = htmlDocument;

  const htmlElements = _.filter(ownerDocument.body.childNodes, (elements) => elements.nodeName !== '#text');
  return _.transform(
    htmlElements,
    (anchors, element: any) => {
      _.forEach([].slice.call(element.getElementsByTagName('a')), (a) => {
        anchors.push(a);
      });
    },
    [],
  );
}
